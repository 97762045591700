var $class="se2--accessibility-toggle",$name="AccessibilityToggle",$path="app/components/AccessibilityToggle/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import forEach from 'lodash/forEach';

export const accessibilityToggleClassName = $class;
export const ariaLabelAttributeName = 'aria-label';
export const ariaCheckedAttributeName = 'aria-checked';
export const falseAttributeValue = 'false';

export default shell.registerComponent($this, ({
  addEventListener, configs, elements, mount, publishMessage, settings, subscribeToMessage,
}) => {
  function toggle() {
    forEach(elements,
      (element) => {
        element.setAttribute(ariaCheckedAttributeName, settings.accessibility);
        element.setAttribute(
          ariaLabelAttributeName,
          settings.accessibility
            ? element.dataset.switchOn
            : element.dataset.switchOff,
        );
      });
  }

  mount((element) => {
    toggle();
    addEventListener(element, 'click', () => publishMessage(
      configs.messages.accessibility,
      !settings.accessibility,
    ));
  });

  subscribeToMessage(configs.messages.accessibility, toggle);
});
